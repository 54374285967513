import React, { useState, useEffect } from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'

// import { initShoppingExpress } from 'services/villageServices'
import { Container } from 'styles/sharedStyle'
import { Title60, Body16, Body12, Body14 } from 'components/Typography'
import theme from 'styles/theme'
import Layout from 'components/Layout'
import CtaHeading from 'components/CtaHeading'

import ServiceBasket from 'components/ServiceBasket/ServiceBasket'

import { useForm, FormDropdown, FormCheckbox, FormText } from 'components/Forms'
import Autocomplete from 'components/Autocomplete'
import Button from 'components/Button'
import Link from 'components/Link'
import { COUNTRIES_PHONE_PREFIX } from 'constants/index'
import {
  navigateFirstInvalid,
  validations,
  prefixMask,
} from 'utils/form-validation'

const ServicesCheckoutPage = (props) => {
  const {
    pageContext: { villageSlug, pageLevel },
    data: {
      page: {
        // hero,
        formMetaData,
        locale,
        jsonLdSchema,
        // village,
      },
    },
  } = props

  const labels = formMetaData.find(
    (metaData) => metaData.__typename === 'ContentfulLabelServicesContactLab08'
  )

  const basketLabels = formMetaData.find(
    (metaData) => metaData.__typename === 'ContentfulLabelsServicesBasketLab05'
  )

  const mobilePrefixOptions = COUNTRIES_PHONE_PREFIX.map(
    (prefix) => `${prefix.text} (${prefix.dial_code})`
  )

  const countries = COUNTRIES_PHONE_PREFIX.map(
    (prefix) => `${prefix.text}`
  ).filter((value, index, self) => self.indexOf(value) === index)

  const {
    validateEmail,
    atLeast2Chars,
    isValidCountry,
    isValidPrefix,
    notNull,
  } = validations()

  const initialValues = {
    leadTravellerTitle: {
      name: 'leadTravellerTitle',
      label: labels.titleDropdown,
      value: '',
      options: [
        {
          id: '0',
          name: '',
          value: '',
        },
        {
          id: '1',
          name: 'Mr.',
          value: '1',
          available: true,
        },
        {
          id: '2',
          name: 'Mrs.',
          value: '2',
          available: true,
        },
        {
          id: '3',
          name: 'Miss.',
          value: '3',
          available: true,
        },
        {
          id: '4',
          name: 'Ms.',
          value: '4',
          available: true,
        },
      ],
      errorMessage: labels.requiredFieldError,
      validation: notNull,
    },
    firstName: {
      name: 'firstName',
      label: labels.firstName,
      value: '',
      errorMessage: labels.requiredFieldError,
      validation: atLeast2Chars,
    },
    lastName: {
      name: 'lastName',
      label: labels.lastName,
      value: '',
      errorMessage: labels.requiredFieldError,
      validation: atLeast2Chars,
    },
    leadTravellerEmail: {
      name: 'leadTravellerEmail',
      label: labels.email,
      value: '',
      errorMessage: labels.emailErrorMessage,
      validation: validateEmail,
    },
    leadTravellerPrefix: {
      name: 'leadTravellerPrefix',
      label: labels.prefixLabel,
      value: '',
      errorMessage: labels.requiredFieldError,
      validation: isValidPrefix,
    },
    leadTravellerPhone: {
      name: 'leadTravellerPhone',
      label: labels.mobileNumberLabel,
      value: '',
      errorMessage: labels.numberErrorMessage,
      validation: atLeast2Chars,
    },
    bookerEmail: {
      name: 'bookerEmail',
      label: labels.bookerEmail,
      value: '',
      errorMessage: labels.emailErrorMessage,
      validation: validateEmail,
    },
    address1: {
      name: 'address1',
      label: labels.addressLine1,
      value: '',
      errorMessage: labels.requiredFieldError,
      validation: notNull,
    },
    address2: {
      name: 'address2',
      label: labels.addressLine2,
      value: '',
      errorMessage: labels.requiredFieldError,
      validation: notNull,
    },
    town: {
      name: 'town',
      label: labels.town,
      value: '',
      errorMessage: labels.requiredFieldError,
      validation: notNull,
    },
    county: {
      name: 'county',
      label: labels.county,
      value: '',
      errorMessage: labels.requiredFieldError,
      validation: notNull,
    },
    postcode: {
      name: 'postcode',
      label: labels.postcode,
      value: '',
      errorMessage: labels.requiredFieldError,
      validation: notNull,
    },
    country: {
      name: 'country',
      label: labels.country,
      value: '',
      errorMessage: labels.requiredFieldError,
      validation: isValidCountry,
    },
    billingPrefix: {
      name: 'billingPrefix',
      label: labels.prefixLabel,
      value: '',
      errorMessage: labels.requiredFieldError,
      validation: isValidPrefix,
    },
    billingPhone: {
      name: 'billingPhone',
      label: labels.mobileNumberLabel,
      value: '',
      errorMessage: labels.numberErrorMessage,
      validation: atLeast2Chars,
    },
    termsAndConditions: {
      name: 'termsAndConditions',
      copy: labels.termsAndConditionsCheckbox,
      value: false,
      errorMessage: labels.requiredFieldError,
      validation: notNull,
    },
    marketingCheckbox: {
      name: 'marketingCheckbox',
      copy: labels.marketingCheckbox,
    },
  }

  const [hasNavigated, setHasNavigated] = useState(true)

  const {
    form,
    handleChange,
    handleCheckbox,
    validateForm,
    handleChangeByField,
    handleNumericChange,
  } = useForm(initialValues)

  const setTravellerPrefix = (value) => {
    handleChangeByField(value, form.leadTravellerPrefix.name)
  }

  const setBillingPrefix = (value) => {
    handleChangeByField(value, form.billingPrefix.name)
  }

  const setCountry = (value) => {
    handleChangeByField(value, form.country.name)
  }

  useEffect(() => {
    if (!hasNavigated) {
      navigateFirstInvalid()
      setHasNavigated(true)
    }
  }, [hasNavigated])

  const submitCheckout = () => {
    if (!validateForm()) {
      setHasNavigated(false)
    } else {
      // 1 - call OPD Book endpoint
      // 2 - redirect to HPP (Url returned by OPD)
    }
  }

  return (
    <Layout {...props}>
      <Helmet>
        {jsonLdSchema && jsonLdSchema.jsonLdSchema ? (
          <script type="application/ld+json">
            {jsonLdSchema.jsonLdSchema}
          </script>
        ) : null}
      </Helmet>
      <Container maxWidth="90%">
        <div style={{ border: 'none', height: '60px' }} />
        <Container
          maxWidth={[780]}
          textAlign="center"
          paddingBottom="70px"
          paddingTop="70px"
          borderBottom>
          <CtaHeading
            pt={['60px', null, '80px']}
            color={theme.colors.sagedark}
            eyebrow={basketLabels.myServices}
            headline={labels.mainTitle}
            body={labels.mainDescription.mainDescription}>
            {labels.mainTitle}
          </CtaHeading>
        </Container>

        <Container maxWidth={[780]}>
          <Title60
            pt={['60px', null, '80px']}
            color={theme.colors.sagedark}
            textAlign="center">
            {labels.leadTravellerSectionTitle}
          </Title60>
          <Container childrenGrid>
            <FormDropdown
              {...form.leadTravellerTitle}
              handleChange={handleChange}
            />
            <FormText {...form.firstName} handleChange={handleChange} />
            <FormText {...form.lastName} handleChange={handleChange} />
            <FormText
              {...form.leadTravellerEmail}
              handleChange={handleChange}
            />
            <Container phoneContainer>
              <Autocomplete
                suggestions={mobilePrefixOptions}
                parentCallback={setTravellerPrefix}
                {...form.leadTravellerPrefix}
                mask={prefixMask}
              />
              <FormText
                {...form.leadTravellerPhone}
                handleChange={handleNumericChange}
              />
            </Container>
          </Container>
        </Container>

        <Container maxWidth={[780]} paddingBottom="50px">
          <Title60
            pt={['60px', null, '80px']}
            color={theme.colors.sagedark}
            textAlign="center">
            {labels.billingDetailsTitle}
          </Title60>
          <Container childrenGrid>
            <FormText {...form.bookerEmail} handleChange={handleChange} />
            <FormText {...form.address1} handleChange={handleChange} />
            <FormText {...form.address2} handleChange={handleChange} />
            <FormText {...form.town} handleChange={handleChange} />
            <FormText {...form.county} handleChange={handleChange} />
            <FormText {...form.postcode} handleChange={handleChange} />
            <Autocomplete
              suggestions={countries}
              parentCallback={setCountry}
              {...form.country}
            />
            <Container phoneContainer>
              <Autocomplete
                suggestions={mobilePrefixOptions}
                parentCallback={setBillingPrefix}
                {...form.billingPrefix}
                mask={prefixMask}
              />
              <FormText
                {...form.billingPhone}
                handleChange={handleNumericChange}
              />
            </Container>
          </Container>
        </Container>

        <Container maxWidth={[780]}>
          <Container textAlign="center" paddingBottom="50px">
            <Body16 as="div" color={theme.colors.sagedark}>
              {labels.security.security}
            </Body16>
            <Body14 color={theme.colors.sagemedium}>
              <Link external to={labels.odrUrl}>
                {labels.odrLinkText}
              </Link>
            </Body14>
          </Container>

          <Container childrenGrid paddingBottom="50px">
            <FormCheckbox
              {...form.termsAndConditions}
              link={`/${villageSlug}/${locale}/${pageLevel}/terms-conditions/`}
              linkText={labels.newWindowLink}
              handleChange={handleCheckbox}
            />
            <FormCheckbox
              {...form.marketingCheckbox}
              handleChange={handleCheckbox}
            />
          </Container>

          <Container paddingBottom="50px">
            <Body12 color={theme.colors.sagedark}>
              {labels.paymentInformation.paymentInformation}
            </Body12>
            <Button
              onClick={submitCheckout}
              // as={Link} to="/"
            >
              {labels.paymentButton}
            </Button>
          </Container>
        </Container>
      </Container>

      <ServiceBasket
        {...basketLabels}
        currency="GBP"
        services={[{ label: 'Shopping Express', total: 30 }]}
        showCheckoutLinks={false}
      />
    </Layout>
  )
}

ServicesCheckoutPage.propTypes = {
  pageContext: PropTypes.oneOfType([PropTypes.object]).isRequired,
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
}

// eslint-disable-line no-unused-vars
export const ServicesCheckoutPageQuery = graphql`
  query(
    $id: String!
    $nodeLocale: String!
    $pageTypeSlug: String
    $villageSlug: String
  ) {
    page: contentfulPageTemplateFormsT10(id: { eq: $id }) {
      jsonLdSchema {
        jsonLdSchema
      }
      hero {
        ...heroMediaQuery
        eyebrow
        headline
      }
      locale: node_locale
      formMetaData {
        __typename
        ... on ContentfulLabelServicesContactLab08 {
          prefixLabel: mobileNumberPrefixLabel
          mobileNumberLabel
          mainTitle
          mainDescription {
            mainDescription
          }
          leadTravellerSectionTitle
          requiredFieldError
          emailErrorMessage
          numberErrorMessage
          titleDropdown
          firstName
          lastName
          email
          billingDetailsTitle
          bookerEmail
          addressLine1
          addressLine2
          town
          county
          country
          postcode
          security {
            security
          }
          odrLinkText
          odrUrl
          termsAndConditionsCheckbox
          marketingCheckbox
          newWindowLink
          paymentInformation {
            paymentInformation
          }
          paymentButton
        }
        ... on ContentfulLabelsServicesBasketLab05 {
          ...serviceBasketLabels
        }
      }
      village {
        name
        code
        villageSlug: slug
        currency
        servicesHeader {
          ...header
        }
        home: page_template_home_t01 {
          footer {
            ...footer
          }
          locale: node_locale
        }
        openingHours {
          ...villageOpeningHours
        }
        defaultLocale
        openingStatusLabel
      }
      pageTitle
      pageDescription
      hideFromSearchEngine
    }
    labels: allContentfulLabelVillageLab09(
      filter: { node_locale: { eq: $nodeLocale } }
    ) {
      edges {
        node {
          visitBoutiqueLabel
        }
      }
    }
    memDaysOnboarding: contentfulCompMemDayOnb01(
      node_locale: { eq: $nodeLocale }
      village: { slug: { eq: $villageSlug } }
      pageTypes: { elemMatch: { slug: { eq: $pageTypeSlug } } }
    ) {
      ...memDaysOnboarding
    }
    allMemorableDays: allContentfulPageTemplateMemorableDaysT14(
      filter: {
        node_locale: { eq: $nodeLocale }
        village: { slug: { eq: $villageSlug } }
      }
    ) {
      edges {
        node {
          ...memDaysSlugs
        }
      }
    }
  }
`

export default ServicesCheckoutPage
